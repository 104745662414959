import {Outlet, Navigate} from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

const ProtectedRoutes = () => {
    const cookie = useSelector(state => state.auth.cookie);
  return (
    cookie !== null ? <Outlet/> : <Navigate to="/login"/>
  )
}

export default ProtectedRoutes;
