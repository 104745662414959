import React, { useEffect, useState } from "react";
import { GraphicWalker } from "@kanaries/graphic-walker";
import './AdvancedAnalytics.scss';
import { useLocation } from "react-router-dom";
const AdvancedAnalytics = () => {
  const {state} = useLocation()
  const [graphicWalkerData, setGraphicWalkerData] = useState(null);
  useEffect(() => {
    // console.log(state.df)
    // setGraphicWalkerData(state.df)
    const storedGraphicWalkerData = localStorage.getItem("graphicWalkerData");
    if (storedGraphicWalkerData) {
      setGraphicWalkerData(JSON.parse(storedGraphicWalkerData));
    }
  }, []);

  return (
    <>
          {graphicWalkerData && (
            <GraphicWalker
              data={graphicWalkerData.dataSource}
              fields={graphicWalkerData.fields}
              appearance="dark"
            />
          )}
    </>
  );
};

export default AdvancedAnalytics;
