import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";

const PaginatedTable = ({ data, columns, rowsPerPage = 5, rowLength }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageState(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };
  console.log(data, rowLength);
  // Slice the data based on pagination

  // const transformData = (data) => {
  //   console.log(typeof(data))
  //   const keys = Object.keys(data);
  //   const rows = data[keys[0]].map((_, index) => {
  //     return keys.reduce((acc, key) => {
  //       acc[key] = data[key][index];
  //       return acc;
  //     }, {});
  //   });
  //   return rows;
  // };
  const transformData = (data) => {
    const keys = Object.keys(data);
    const numRows = Object.keys(data[keys[0]]).length; // Get the number of rows from the first column's keys
  
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      const row = {};
      keys.forEach((key) => {
        row[key] = data[key][i];
      });
      rows.push(row);
    }
  
    return rows;
  };
  
  const transformedData = transformData(data);
  console.log(transformedData);
  const count = transformedData.length;
  const paginatedData = transformedData.slice(
    page * rowsPerPageState,
    page * rowsPerPageState + rowsPerPageState
  );

  // Function to convert the data into graphic walker format
  const convertData = (data) => {
    const keys = Object.keys(data);
    const length = data[keys[0]].length;
    const formattedData = [];

    for (let i = 0; i < length; i++) {
      const row = {};
      keys.forEach((key) => {
        row[key] = data[key][i];
      });
      formattedData.push(row);
    }

    return formattedData;
  };

  const handleAdvancedAnalysis = (tableData, cols) => {
    const fields = cols.map((col, index) => ({
      fid: col,
      semanticType: "nominal",
      analyticType: "dimension",
    }));
    const dataSource = convertData(tableData);
    console.log(fields);
    console.log(dataSource);
    const graphicWalkerData = {
      fields,
      dataSource,
    };
    localStorage.setItem(
      "graphicWalkerData",
      JSON.stringify(graphicWalkerData)
    );
    window.open("/advancedAnalytics", "_blank");
  };

  return (
    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
      <div style={{ minWidth: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col}>{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, rowIndex) => {
              console.log(paginatedData);
              return (
                <TableRow key={rowIndex}>
                  {columns.map((col) => (
                    <TableCell key={col}>{row[col]}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPageState}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: "11px", fontSize: "12px" }}
          className="custom-ask-btn"
          onClick={() => handleAdvancedAnalysis(data, columns)}
        >
          Advanced Analysis
        </Button>
      </div>
    </TableContainer>
  );
};

export default PaginatedTable;
