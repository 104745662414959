import { useGoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { signIn } from "../../state/actions";
import { useNavigate } from "react-router-dom";
import koelLogo from "../../resources/koel.png";
import actinLogo from "../../resources/actin.png";
import "./LoginPage.scss";
import { getCookie, setCookie } from "../../utils/UtilityFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (googleResponse) => {
      try {
        const userInfo = await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
              Authorization: `Bearer ${googleResponse.access_token}`,
            },
          })
          .then((res) => res.data);
        var userEmail = userInfo.email;
        var userEmailDomain = userEmail.slice(userEmail.indexOf("@") + 1);
        if (userEmailDomain === "kirloskar.com") {
          setCookie("token", googleResponse.access_token, 6);
          dispatch(
            signIn(
              googleResponse.access_token,
              userInfo.picture,
              userInfo.name,
              getCookie("token"),
              userInfo.email,
            )
          );
          navigate("/home");
        } else {
          toast.error("Please use email having kirloskar.com domain", {
            position: "top-right",
            autoClose: 2500,
            theme: "colored"
          });
          navigate("/login");
        }
      } catch (error) {
        toast.error(error.response.data, {
          position: "top-right",
          autoClose: 2500,
          theme: "colored"
        });
        console.log(error);
      }
    },
    onError: (error) => {
      console.log( error);
    },
  });

  const handleLogin = () => {};

  return (
    <>
      <div className="login login-utility w-100 h-100">
        <div className="login-container login-utility">
          <div className="logo-container">
            <img src={koelLogo} alt="Logo" className="logo-img" />
          </div>
          <div className="form-container">
            <form className="form-container w-100"> 
              <label htmlFor="username" className="custom-login-label">
                Username:
              </label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="custom-input"
              />
              <label htmlFor="password" className="custom-login-label">
                Password:
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="custom-input"
              />
              <button type="submit" onClick={handleLogin} className="login-btn">
                Login
              </button>
            </form>
            <hr />
            <button
              type="button"
              className="login-with-google-btn"
              onClick={() => loginWithGoogle()}
            >
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        theme="colored"
      />
    </>
  );
};

export default LoginPage;
