import React from "react";
import "./Card.scss";
import { Link } from "react-router-dom";

const Card = ({ cardTitle, cardInfo, cardLogo, linkTo }) => {
  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-3 pbpt res-height" style={{height: '100%'}}>
        <Link to={linkTo} style={{ textDecoration: "none", height: "100%" }}>
          <div className="custom-card">
            <div className="card-header"> 
              <div className="card-logo">
                <div className="logo-wrapper">{cardLogo}</div>
              </div>
              <div className="card-title">
                <h4>{cardTitle}</h4>
              </div>
            </div>
            <div className="card-info">
              <p>{cardInfo}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
