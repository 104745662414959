import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./state/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* store is redux store. this is redux wrapper for complete app */}
    <Provider store={store}>
      {/* GoogleAuthProvider is the Google auth wrapper for complete app. this helps in using sign in with google functionality */}
      <GoogleOAuthProvider clientId='240530936771-i99853us7pb9pjhfdilrnksngp409ths.apps.googleusercontent.com'>
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);
