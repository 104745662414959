import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import HomePage from "./components/HomePage/HomePage";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "./components/PageLayout/PageLayout";
import KnowYourHR from "./components/KnowYourHR/KnowYourHR";
import KnowYourIT from "./components/KnowYourIT/KnowYourIT";
import ChatWIthYourPDF from "./components/ChatWithYourPDF/ChatWithYourPDF";
import KnowOurEngine from "./components/KnowOurEngine/KnowOurEngine";
import React, { useEffect } from "react";
import GeneralChat from "./components/GeneralChat/GeneralChat";
import { _get } from "./api/client";
import { INIT_ROUTES } from "./state/actions";
import DynamicComponent from "./components/DynamicComponent/DynamicComponent";
import DataAnalysis from "./components/DataAnalysis/DataAnalysis";
import { DynamicComponentWrapper } from "./utils/UtilityFunctions";
import AdvancedAnalytics from "./components/AdvancedAnalytics/AdvancedAnalytics";
function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  console.log("AUTH", auth)
  const widgetRoutes = useSelector((state) => state.widgetroute);
  useEffect(() => {
    const getWidgetRoutes = async () => {
      try{
        const response = await _get('/get_widgets');
        dispatch(INIT_ROUTES(response.data));
      }
      catch(error){
        console.log(error);
      }
    };
    console.log("in use effect");
    getWidgetRoutes();
  }, [dispatch]);
  console.log(widgetRoutes);
  return (
    <>
      <div className="App-container">
        <Router>
          {auth.cookie === null && <Navigate to="/login" />}
          <Routes>
            <Route
              path="/login"
              element={
                auth.cookie === null ? <LoginPage /> : <Navigate to="/home" />
              }
            />
            <Route element={<ProtectedRoutes />}>
              <Route element={<PageLayout />}>
                <Route element={<HomePage />} path="/home" exact />
                <Route element={<KnowYourHR />} path="/hr" exact />
                <Route element={<KnowYourIT />} path="/it" exact />
                <Route element={<ChatWIthYourPDF />} path="/chat" exact />
                <Route element={<KnowOurEngine />} path="/engine" exact />
                <Route element={<GeneralChat />} path="/generalchat" exact />
                <Route element={<DataAnalysis />} path="/analysis" exact />
                <Route path="/advancedAnalytics" element={<AdvancedAnalytics />} />
                {widgetRoutes.length > 0 &&
                  widgetRoutes.map((route, i) => {
                    console.log(route)
                    return (  
                      <Route
                        path={`/:endpoint`}
                        element={
                          <DynamicComponentWrapper routes={widgetRoutes} />
                          // <DynamicComponent nameOnMain={route.nameOnMain} />
                        }
                        key={i}
                        exact
                      />
                    );
                  })}
              </Route>
            </Route>
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
