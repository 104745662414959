const INITIAL_STATE = {
  chatHistory: [],
};

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CHAT_HISTORY":
      return {
        ...state,
        chatHistory: [...state.chatHistory, action.chatHistory],
      };
    case "RESET_CHAT":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default chatReducer;
