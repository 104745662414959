import axios from "axios";

const BASE_URL = "https://koelaihub.kirloskar.com:5000";
// const BASE_URL = "http://20.193.241.156:11000";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

const _get = (url, config) => {
  // const config = {
  //   headers: headers,
  // };
  return apiClient.get(url, config);
};

const _post = (url, data = {}, headers= {}) => {
  const config = {
    headers: headers,
  };
  return apiClient.post(url, data, config);
};

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 400) {
        console.error("Bad Request:", error.response.data);
        // Handle error 400 here, you can display a message to the user or take other actions
      } else if (error.response.status === 500) {
        console.error("Internal Server Error:", error.response.data);
        // Handle error 500 here, you can display a message to the user or take other actions
      } else {
        console.error(
          "Response Error:",
          error.response.status,
          error.response.data
        );
      }
    } else if (error.request) {
      // return error
      // The request was made but no response was received
      console.error("Request Error:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export { _get, _post };
