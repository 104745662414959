// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import koelLogo from "../../resources/koel.png";
import actinLogo from "../../resources/actin.png";
import "./SideBar.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosChatboxes } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { GrHomeRounded } from "react-icons/gr";
import { GrDocumentText } from "react-icons/gr";
import { ImProfile } from "react-icons/im";
import { PiEngine } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import {
  INIT_ROUTES,
  sideMenuToggleClose,
  sideMenuToggleOpen,
  signOut,
} from "../../state/actions";
import { deleteCookie } from "../../utils/UtilityFunctions";
import { _get } from "../../api/client";

const SideBar = () => {
  const auth = useSelector((state) => state.auth);
  const widgetRoutes = useSelector((state) => state.widgetroute);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = useState(false);

  useEffect(() => {
    
    const getWidgetRoutes = async () => {
      try{
        const response = await _get("/get_widgets");
        dispatch(INIT_ROUTES(response.data)); 
      }
      catch(e){
        console.log(e)
      }
    };
    getWidgetRoutes();
  }, [dispatch]);

  const handleLogout = () => {
    deleteCookie("token");
    dispatch(signOut());
    navigate("/login");
    // setAnchorEl(null);
  };

  const openHamburgerMenu = () => {
    setOpenSideBar(!openSideBar);
    dispatch(sideMenuToggleOpen());
  };

  const closeHamburgerMenu = () => {
    setOpenSideBar(!openSideBar);
    dispatch(sideMenuToggleClose());
  };

  const handleLinkClick = (name) => {
    if (name !== "Log Out") {
      setOpenSideBar(!openSideBar);
      dispatch(sideMenuToggleClose());
    } else {
      handleLogout();
    }
  };

  const sideBarItems = [
    {
      path: "/home",
      name: "Home",
      icon: <GrHomeRounded />,
    },
    {
      path: "/hr",
      name: "Know HR Policy",
      icon: <ImProfile />,
    },
    {
      path: "/chat",
      name: "Chat with your PDF",
      icon: <GrDocumentText />,
    },
    {
      path: "/it",
      name: "Know IT Policy",
      icon: <ImProfile />,
    },
    {
      path: "/engine",
      name: "Know Our Engines",
      icon: <PiEngine />,
    },
    {
      path: "/generalchat",
      name: "KOEL Assistant",
      icon: <IoIosChatboxes />,
    },
    {
      path: "/analysis",
      name: "Data Analysis",
      icon: <GrDocumentText />,
    },
    // {
    //   path: "",
    //   name: "Log Out",
    //   icon: <CiLogout />,
    // },
  ];

  return (
    <Paper>
      <div className="hamburger-logo-container">
        {openSideBar === true ? (
          <RxCross2 onClick={closeHamburgerMenu} />
        ) : (
          <GiHamburgerMenu onClick={openHamburgerMenu} />
        )}
      </div>
      <div
        className={`sidebar ${
          openSideBar === true ? "" : "responsive-sidebar"
        }`}
      >
        <div className="top-section">
          <div className={"logo--container"}>
            <img src={koelLogo} alt="" className="logo" />
          </div>
        </div>
        <div className={"sidebar-item-container"}>
          {sideBarItems.map((item, index) => {
            return (
              <NavLink
                to={item.path}
                key={index}
                className={({ isActive }) =>
                  isActive ? "link-active" : "link"
                }
                onClick={() => handleLinkClick(item.name)}
              >
                <div className="icon">{item.icon}</div>
                <div className="link-text">{item.name}</div>
              </NavLink>
            );
          })}
          {widgetRoutes.length > 0 &&
            widgetRoutes.filter(route =>
              
              route.user_emails.includes(auth.email)).map((route, i) => {
                console.log(route)
              return (
                <NavLink
                  to={`/${route.widget_name}`}
                  key={i}
                  className={({ isActive }) =>
                    isActive ? "link-active" : "link"
                  }
                  onClick={() => handleLinkClick(route.nameOnMain)}
                > 
                  <div className="icon">
                    <ImProfile />
                  </div>
                  <div className="link-text">{route.nameOnMain}</div>
                </NavLink>
              );
            })}
          <NavLink
            to=''
            className={({ isActive }) => (isActive ? "link-active" : "link")}
            onClick={() => handleLinkClick("Log Out")}
          >
            <div className="icon">
              <CiLogout />
            </div>
            <div className="link-text">Log Out</div>
          </NavLink>
        </div>
        <div className={"profile-container"}>
          <Button
            id="basic-button"
            aria-haspopup="true"
            disableRipple
            sx={{
              color: "rgb(27, 153, 139)",
              fontWeight: "bold",
              justifyContent: "start",
              gap: "10px",
              textTransform: "capitalize",
              fontSize: "15px",
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <div className="profile-icon-container">
              <img
                src={auth.userProfileImage}
                alt=""
                className="profile-icon-img"
              />
            </div>
            <div className="profile-text-container">{auth.name}</div>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default SideBar;
