import './PageLayout.scss';
import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import { useSelector } from 'react-redux';
const PageLayout = () => {
  const commonStates = useSelector((state) => state.util);
  return (
    <>
      {/* <Grid container sx={{height: '100%', flexDirection:'column'}}>
        <Grid item md={2} sx={{width: 'fit-content'}}>
          <SideBar />
        </Grid>
        <Grid item md={0.5}>
        </Grid>
        <Grid item md={9.5}>
          <Outlet />
        </Grid>
      </Grid> */}

      <div className='left-container'>
        <SideBar/>
      </div>
      <div className={`${commonStates.isMenuOpen ? 'd-none' : ''} right-container`}>
         <Outlet/>
      </div>
    </>
  );
};

export default PageLayout;
