import React, { useEffect, useState } from "react";
import "../KnowYourHR/KnowYourHR.scss";
import { useDispatch } from "react-redux";
import ChatWindow from "../ChatWindow/ChatWindow";
import { resetChatOnRouteChange } from "../../state/actions";
import AskQuery from "../AskQuery/AskQuery";
import Disclaimer from "../Disclaimer/Disclaimer";
import { _get } from "../../api/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";


const DynamicComponent = ({nameOnMain}) => {
  const { endpoint } = useParams();
  console.log(endpoint)
  const dispatch = useDispatch();
  const [policyList, setPolicyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getHRPolicies = async () => {
    try{
      const response = await _get(`/get_files?policy=${endpoint}`)
      console.log(response.data.policy_list)
      setPolicyList(response.data.policy_list)
    } 
    catch(error){
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored"
      });
    }

  }


  useEffect(() => {
    dispatch(resetChatOnRouteChange());
    getHRPolicies();
  }, [endpoint]);
  
  return (
    <>
      <div className="right-pane d-flex flex-column">
        <ChatWindow loading={loading}/>
        <Disclaimer componentName={nameOnMain} policies={true}/>
        {policyList.length > 0 && <AskQuery policyName={endpoint} url="/ask_department" setLoading={setLoading}/>}  
      </div>
    </>
  );
};

export default DynamicComponent;
