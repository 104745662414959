import React, { useEffect, useState } from "react";
import "../KnowYourHR/KnowYourHR.scss";
import { useDispatch } from "react-redux";
import { resetChatOnRouteChange } from "../../state/actions";
import ChatWindow from "../ChatWindow/ChatWindow";
import AskQuery from "../AskQuery/AskQuery";
import Disclaimer from "../Disclaimer/Disclaimer";

const KnowOurEngine = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(resetChatOnRouteChange());
  }, []);
  return (
    <>
      <div className="right-pane d-flex flex-column">
        <ChatWindow loading={loading}/>
        <Disclaimer componentName="Know Our Engines" policies={false}/>
        <AskQuery policyName="Engine" url="/ask_engine" setLoading={setLoading}/>
      </div>
    </>
  );
};

export default KnowOurEngine;
