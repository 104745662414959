import React, { useEffect, useState } from "react";
import CustomInstructions from "../CustomInstructions/CustomInstructions";
import { useDispatch, useSelector } from "react-redux";
import { setChatHistory } from "../../state/actions";
import "./AskQuery.scss";
import { Button, TextField } from "@mui/material";
import { _get, _post } from "../../api/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AskQuery = ({ policyName, url, setLoading }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("Active Voice");
  const [selectedResponse, setSelectedResponse] = useState("Formal");
  const [selectedToken, setSelectedToken] = useState("100");
  const [userInput, setUserInput] = useState("");
  const [policyList, setPolicyList] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(
    policyList && policyList[0] 
  );

  const getHRPolicies = async () => {
    try{
      const response = await _get(`/get_files?policy=${policyName}`)
      console.log(response.data.policy_list)
      setPolicyList(response.data.policy_list)
    } 
    catch(error){
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored"
      });
    }

  }

  useEffect(() => {
    if(policyName !== "General"){
      console.log("Policy Name:", policyName);
      getHRPolicies();  
    }
  }, [policyName]); 
  useEffect(() => {
    setSelectedPolicy(policyList[0])
    console.log(selectedPolicy)
    console.log(policyList)}, [policyList])

  const handleCustomInstructions = () => {  
    setOpenModal(true);
  };

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      askQuestion();
    }
  };

  const askQuestion = async () => {
    try {
      if (policyName === "Engine" || "General") {
        dispatch(
          setChatHistory({
            // role: `${auth.userProfileImage}`,
            role: "User",
            text: userInput,
          })
        );
        setLoading(true);
      } else {
        dispatch(
          setChatHistory({
            role: "User",
            text: userInput + `\t(Selected Policy: ${selectedPolicy})`,
          })
        );
        setLoading(true);
      }

      setUserInput("");
      var answer = "";
      if (policyName === "General") {
        const response = await _post(`${url}?text=${userInput}`, {
          "Content-Type": "application/json",
        });
        answer = response.data.Response;
      } else {
        const response = await _post(
          `${url}?policyType=${policyName}&text=${userInput}`,
          {
            policyName: selectedPolicy,
            // max_tokens: selectedToken,
          },
          { "Content-Type": "application/json" }
        );
        answer = response.data.Response;
      }

      if (policyName === "Engine") {
        dispatch(setChatHistory({ role: "Engine", text: answer }));
        setLoading(false);
      } else if (policyName === "General") {
        dispatch(setChatHistory({ role: "KOEL Assistant", text: answer }));
        setLoading(false);
      } else {
        dispatch(setChatHistory({ role: selectedPolicy, text: answer }));
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      console.error("Error during QNA:", error);
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await _get(
        `/download?fileName=${selectedPolicy}&policy=${policyName}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedPolicy}`); // Replace with the desired file name

      // // Append the anchor to the body
      document.body.appendChild(link);

      // // Programmatically trigger a click event on the anchor
      link.click();

      // // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("File Downloaded successfully", {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
        style: { backgroundColor: "rgb(27, 153, 139)" },
      });
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="container question-box-container">
        <div className="user-query">
          {policyName === "General" ? (
            <></>
          ) : (
            <div className="navigation">
              <div className="nav-hr">
                {policyName === "Engine" ? (
                  <></>
                ) : (
                  <>
                    <label htmlFor="policyDropdown" className="custom-label">
                      Select {policyName} Policy:
                    </label>
                    <div className="custom-select">
                      <select
                        id="policyDropdown"
                        value={selectedPolicy}
                        onChange={(e) => setSelectedPolicy(e.target.value)}
                        className="sel-cus"
                      >
                        {policyList &&
                          policyList.map((policy) => (
                            <option value={policy} key={policy}>
                              {policy}
                            </option>
                          ))}
                        ;
                      </select>
                    </div>
                  </>
                )}
                <div className="d-flex">
                  <div className="nav-ci">
                    <Button
                      onClick={handleCustomInstructions}
                      className="custom-inst-btn"
                    >
                      Custom Instructions
                    </Button>
                    {openModal && (
                      <CustomInstructions
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        selectedVoice={selectedVoice}
                        setSelectedVoice={setSelectedVoice}
                        selectedResponse={selectedResponse}
                        setSelectedResponse={setSelectedResponse}
                        selectedToken={selectedToken}
                        setSelectedToken={setSelectedToken}
                      />
                    )}
                  </div>
                  {policyName === "Engine" ? (
                    <></>
                  ) : (
                    <div className="download-btn">
                      <Button
                        className="custom-inst-btn"
                        disableRipple
                        onClick={handleDownload}
                      >
                        Download Policy
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="user-query-text-btn">
            <TextField
              label="Type your question..."
              type="text"
              variant="standard"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              style={{ width: "100%", marginRight: "20px" }}
              className="textfield"
              InputLabelProps={{
                sx: {
                  ".Mui-focused": {
                    color: "#1b998b",
                  },
                  color: "#1b998b",
                },
              }}
              InputProps={{
                sx: {
                  "&:focus": {
                    borderBottom: "1px solid #1b998b",
                  },
                  borderBottom: "1px solid #1b998b",
                },
              }}
              onKeyPress={handleKeyPress}
            />
            <Button
              variant="contained"
              onClick={askQuestion}
              className="custom-ask-btn"
            >
              Ask
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2500} theme="colored" />
    </>
  );
};

export default AskQuery;
