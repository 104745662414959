import React, {useEffect} from "react";
// import { styled } from "@mui/material/styles";
// import { Paper } from "@mui/material";
import "./HomePage.scss";
import { GrDocumentText } from "react-icons/gr";
import { ImProfile } from "react-icons/im";
import { PiEngine } from "react-icons/pi";
import { IoIosChatboxes } from "react-icons/io";
import Card from "../Card/Card";
import { AnyPolicyCardInfo } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { _get } from "../../api/client";
import { INIT_ROUTES } from "../../state/actions";

// const Item = styled(Paper)(({ themsvge }) => ({  
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

const homePageItems = [
  {
    path: "/hr",
    name: "Know HR Policy",
    icon: <ImProfile className="svg-custom"/>,
    cardInfo: AnyPolicyCardInfo("HR"),
  },
  {
    path: "/chat",
    name: "Chat with your PDF",
    icon: <GrDocumentText className="svg-custom"/>,
    cardInfo: "Too busy to read? Let us answer questions on any of your document!",
  },
  {
    path: "/it",
    name: "Know IT Policy",
    icon: <ImProfile className="svg-custom"/>,
    cardInfo: AnyPolicyCardInfo("IT"),
  },
  {
    path: "/engine",
    name: "Know Our Engines",
    icon: <PiEngine className="svg-custom"/>,
    cardInfo:"Get to understand the KOEL Engine Portfolio."
  },
  {
    path: "/generalchat",
    name: "KOEL Assistant",
    icon: <IoIosChatboxes className="svg-custom"/>,
    cardInfo: "Get solutions any of your problems.",
  },
  {
    path: "/analysis",
    name: "Data Analysis",
    icon: <GrDocumentText className="svg-custom"/>,
    cardInfo: "Get quick analysis on any of your documents.",
  },
];

const HomePage = () => {
  const widgetRoutes = useSelector((state) => state.widgetroute);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  useEffect(() => {
    
    const getWidgetRoutes = async () => {
      try{
        const response = await _get("/get_widgets");
        dispatch(INIT_ROUTES(response.data));
      }
      catch(e){
        console.log(e)
      }
    };
    getWidgetRoutes();
  }, [dispatch]);
  return (
    <div>
      {/* <Paper
        className={`right-pane-home d-flex flex-column`}
      > */}
      <div className="wrapper">
        <div className="top">
          <h2>WELCOME TO KOEL AI HUB</h2>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center card-container">
          <h1 className="starter-h1">Hi, how can I help you today..?</h1>
          <div className="grid gap-custom d-flex row align-items-center justify-content-center">
            {homePageItems.map((item, index) => {
              return (
                <Card
                  cardTitle={item.name}
                  cardInfo={item.cardInfo}
                  cardLogo={item.icon}
                  linkTo={item.path}
                />
              );
            })}
            {widgetRoutes.length > 0 && widgetRoutes.filter(route =>
              route.user_emails.includes(auth.email)).map((item, index) => {
                console.log(item)
              return (
                <Card
                  cardTitle={item.nameOnMain}
                  cardInfo={item.tagLine}  
                  cardLogo={<ImProfile className="svg-custom"/>}
                  linkTo={'/'+item.widget_name}
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* </Paper> */}
    </div>
  );
};

export default HomePage;



            {/* <Card
              cardTitle="Know HR Policy"
              cardInfo="Get quick answers to your queries regarding any HR
                      policies."
              cardLogo={<ImProfile className="svg-custom" />}
              linkTo="/hr"
            />
            <Card
              cardTitle="Chat with your PDF"
              cardInfo="Too busy to read? Let us answer questions on your
                      document!"
              cardLogo={<GrDocumentText className="svg-custom" />}
              linkTo="/chat"
            />
            <Card
              cardTitle="Know IT Policy"
              cardInfo="Get quick answers to your queries regarding any IT
                      policies."
              cardLogo={<ImProfile className="svg-custom" />}
              linkTo="/it"
            /> */}
            {/* <Card
              cardTitle="Know Our Engines"
              // cardInfo="Get quick answers to your queries regarding our Engines."
              cardInfo="Get to understand the KOEL Engine Portfolio."
              cardLogo={<PiEngine className="svg-custom" />}
              linkTo="/engine"
            /> */}
            {/* <Card
              cardTitle="ACTIN Assistant"
              // cardInfo="Get quick answers to your queries regarding our Engines."
              cardInfo="Get solutions any of your problems."
              cardLogo={<IoIosChatboxes className="svg-custom" />}
              linkTo="/generalchat"
            /> */}
