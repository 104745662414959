const initialState = [];

const widgetRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_ROUTES":
      return action.payload;
    default:
      return state;
  }
};

export default widgetRouteReducer;
