import { getCookie } from "../../utils/UtilityFunctions";

const INITIAL_STATE = {
    isSignedIn: false,
    userProfileImage: localStorage.getItem('userProfileImage') || null,
    token: null,
    name: localStorage.getItem('name') || null,
    cookie: getCookie("token") || null,
    email: localStorage.getItem('email') || null
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {...state, isSignedIn: true, userProfileImage: action.userProfileImage, token: action.token, name: action.name, cookie: action.cookie, email: action.email };
        case 'SIGN_OUT':
            return {...state, isSignedIn: false, userProfileImage: null, token: null, name: null, cookie: null };
        default:
            return state;
    }
};

export default authReducer;
