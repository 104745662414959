import React from "react";
import "./Disclaimer.scss";
import { CiWarning } from "react-icons/ci";

const Disclaimer = ({ componentName, policies }) => {
  return (
    <div className="disclaimer-container">
      <div className="caution-logo">
        <CiWarning style={{height:'18px', width: '18px'}}/>
      </div>
      <span> 
        {componentName === "Chat Assistant" && `The response from "${componentName}" is an AI generated response and may have some inconsistencies.`}
        {componentName !== "Chat Assistant" && `The response from chat assistance "${componentName}" is an AI generated response and may have some inconsistencies. For correct information please refer to the respective ${policies ? "policy document" : "document"}.`}
       {/* The response from chat assistance "{componentName}" is an AI generated response and may have some inconsistencies. For correct information please refer to the respective {policies ? "policy document" : "document"}.  */}
        <span>{policies ? " The policies are available for download." : ""}</span>
      </span>
    </div>
  );
};

export default Disclaimer;
