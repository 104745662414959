import React, { useState, useRef, useEffect } from "react";
import "./ChatWithYourPDF.scss";
import { Button, CircularProgress, TextField } from "@mui/material";
import { GrAttachment } from "react-icons/gr";
import { styled } from "@mui/material/styles";
import CustomInstructions from "../CustomInstructions/CustomInstructions";
import Disclaimer from "../Disclaimer/Disclaimer";
import { _post } from "../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { setChatHistory, resetChatOnRouteChange } from "../../state/actions";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ChatWithYourPDF = () => {
  const chatHistory = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [uploadedDoc, setUploadedDoc] = useState();
  const [userInput, setUserInput] = useState("");

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [toggleShowTips, setToggleShowTips] = useState(false);

  const [selectedVoice, setSelectedVoice] = useState("Active Voice");
  const [selectedResponse, setSelectedResponse] = useState("Formal");
  const [selectedToken, setSelectedToken] = useState("100");

  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const handlePopoverClose = () => {
    setPopperAnchorEl(null);
    setToggleShowTips(false);
    setIsPopoverOpen(false);
  };

  const handleClickOutsidePopover = (event) => {
    if (popperAnchorEl && !popperAnchorEl.contains(event.target)) {
      handlePopoverClose();
    }
  };

  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 7 * 1000 * 1024) {
          toast.error('File with maximum size of 7MB is allowed', {
            position: "top-right",
            autoClose: 2500,
            theme: "colored"
          });
        } else {
          setUploadedDoc(file);
        }
      }
      setUploadedDoc(file);
      const formData = new FormData();
      formData.append("file", file);
      setFileLoading(true);
      await _post("/upload_file_main?widget_type=CWP", formData, {
        "Content-Type": "multipart/form-data",
      });
      setFileLoading(false);
      toast.success("File uploaded successfully!", {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
        style: { backgroundColor: "rgb(27, 153, 139)" },
      });
      setFileUploadedSuccessfully(true);
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      setFileUploadedSuccessfully(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("click", handleClickOutsidePopover);
    } else {
      document.removeEventListener("click", handleClickOutsidePopover);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutsidePopover);
    };
  }, [isPopoverOpen]);

  const handleCustomInstructions = () => {
    setOpenModal(true);
  };

  const askQuestion = async () => {
    try {
      dispatch(
        setChatHistory({
          role: "User",
          text: `${userInput} \t(Uploaded Document: ${uploadedDoc.name})`,
        })
      );
      setLoading(true);
      setUserInput("");
      const formData = new FormData();
      formData.append("file", uploadedDoc);
      const response = await _post(
        `/chat_with_pdf?text=${userInput}`,
        {
          filename: uploadedDoc.name,
          // max_tokens: 500,
        },
        { "Content-Type": "application/json" }
      );
      const answer = response.data.Response;
      dispatch(setChatHistory({ role: "KAI", text: answer }));
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      setLoading(false);
      console.error("Error during summarization:", error);
      // Handle the error as needed
    }
  };

  const ref = useRef();
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chatHistory.length]);
  useEffect(() => {
    const chatDisplay = document.getElementById("chat-display");
    chatDisplay.scrollTop = chatDisplay.scrollHeight;
  }, [chatHistory]);

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      askQuestion();
    }
  };

  useEffect(() => {
    dispatch(resetChatOnRouteChange());
  }, []);
  return (
    <>
      <div className={`right-pane-summ d-flex flex-column`}>
        <div
          className={`chat-window-summ ${
            chatHistory.chatHistory.length > 0 ? "add-bg-summ" : ""
          }`}
          id="chat-display"
        >
          {fileUploadedSuccessfully ? (
            <div className="chat-display-summ">
              {chatHistory.chatHistory.map((item, index) => (
                <>
                  <div
                    key={index}
                    id={`question-answer-summ`}
                    className={`${item.role === "User" ? "user" : "chat"}`}
                  >
                    <strong>
                      {item.role === "User" ? (
                        <img
                          src={auth.userProfileImage}
                          className="role"
                          alt={item.role}
                        />
                      ) : (
                        <span>{item.role}: </span>
                      )}
                    </strong>{" "}
                    <span
                      style={{
                        fontWeight: item.role === "User" ? "bold" : "normal",
                      }}
                    >
                      {item.role === "User" ? (
                        <>{item.text}</>
                      ) : (
                        <pre
                          style={{ whiteSpace: "pre-wrap", display: "inline" }}
                        >
                          {item.text}
                        </pre>
                      )}
                    </span>
                    {/* <span
                      style={{
                        fontWeight: item.role === "User" ? "bold" : "normal",
                      }}
                    >
                      {item.text}
                    </span> */}
                  </div>
                  <div ref={ref} />
                </>
              ))}
              {loading === true && (
                <Loader
                  type="ping-cube"
                  bgColor="rgb(27, 153, 139)"
                  color="rgb(27, 153, 139)"
                  size={50}
                />
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <p>Please choose a file to ask questions on it</p>
            </div>
          )}
        </div>
        <Disclaimer componentName="Chat With Your PDF" policies={false} />
        <div className="container question-box-container">
          <div className="user-query-summ">
            <div className="navigation-summ justify-content-between">
              <div className="nav-hr-summ">
                <div className="custom-upload-summ">
                  <Button
                    component="label"
                    className="custom-upload-btn-summ"
                    startIcon={<GrAttachment />}
                    onChange={handleFileUpload}
                  >
                    {fileUploadedSuccessfully
                      ? uploadedDoc.name
                      : "Choose File"}
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </div>
                <div className="nav-ci">
                  <Button
                    onClick={handleCustomInstructions}
                    className="custom-inst-btn-summ"
                  >
                    Custom Instructions
                  </Button>
                  {openModal && (
                    <CustomInstructions
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      selectedVoice={selectedVoice}
                      setSelectedVoice={setSelectedVoice}
                      selectedResponse={selectedResponse}
                      setSelectedResponse={setSelectedResponse}
                      selectedToken={selectedToken}
                      setSelectedToken={setSelectedToken}
                    />
                  )}
                </div>
                {fileLoading && (
                  <div className="d-flex flex-row align-items-baseline gap-2">
                    <CircularProgress />
                    <div> Your file is being uploaded</div>
                  </div>
                )}
              </div>
            </div>
            {fileUploadedSuccessfully ? (
              <div className="user-query-text-btn">
                <TextField
                  label="Type your question..."
                  type="text"
                  variant="standard"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  style={{ width: "100%", marginRight: "20px" }}
                  className="textfield"
                  InputLabelProps={{
                    sx: {
                      ".Mui-focused": {
                        color: "#1b998b",
                      },
                      color: "#1b998b",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "&:focus": {
                        borderBottom: "1px solid #1b998b",
                      },
                      borderBottom: "1px solid #1b998b",
                    },
                  }}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  variant="contained"
                  onClick={askQuestion}
                  className="custom-ask-btn"
                >
                  Ask
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2500} theme="colored" />
    </>
  );
};

export default ChatWithYourPDF;
