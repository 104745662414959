import React from 'react';
import { useParams } from 'react-router-dom';
import DynamicComponent from '../components/DynamicComponent/DynamicComponent';

export const getCookie = (name) => {
  const cookies = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));

  return cookies ? cookies.split("=")[1] : null;
};

export const setCookie = (name, value, hours) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000); // Convert hours to milliseconds

  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const DynamicComponentWrapper = ({routes}) => {
  const { endpoint } = useParams();
  const route = routes.find(route => route.widget_name === endpoint);
  return route ? <DynamicComponent nameOnMain={route.nameOnMain} /> : <div>404 Not Found</div>
};