export const signIn = (token, profileImage, name, cookie, email) => {
  localStorage.setItem("userProfileImage", profileImage);
  localStorage.setItem("token", token);
  localStorage.setItem("name", name);
  localStorage.setItem("email", email);
  return (dispatch) => {
    dispatch({
      type: "SIGN_IN",
      userProfileImage: profileImage,
      token: token,
      name: name,
      cookie: cookie,
      email: email,
    });
  };
};

export const signOut = () => {
  localStorage.removeItem("userProfileImage");
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  return (dispatch) => {
    dispatch({
      type: "SIGN_OUT",
    });
  };
};

export const sideMenuToggleOpen = () => {
  return (dispatch) => {
    dispatch({
      type: "SIDE_MENU_TOGGLE_OPEN",
    });
  };
};

export const sideMenuToggleClose = () => {
  return (dispatch) => {
    dispatch({
      type: "SIDE_MENU_TOGGLE_CLOSE",
    });
  };
};

export const setChatHistory = ({ role, text }) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CHAT_HISTORY",
      chatHistory: {role, text},
    });
  };
};

export const resetChatOnRouteChange = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_CHAT"
    })
  }
}


export const INIT_ROUTES = (routes) => {
  return (dispatch) => {
    dispatch({
      type: "INIT_ROUTES",
      payload: routes
    });
  };
};