import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './CustomInstructions.scss';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '34rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    borderRadius: '0.75rem'
};
const CustomInstructions = ({ openModal, setOpenModal, selectedVoice, setSelectedVoice, selectedResponse, setSelectedResponse, selectedToken, setSelectedToken }) => {
    const [instruction, setInstruction] = useState('');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [toggleShowTips, setToggleShowTips] = useState(false);

    const openPopper = Boolean(popperAnchorEl);


    const handlePopoverClose = () => {
        setPopperAnchorEl(null);
        setToggleShowTips(false);
        setIsPopoverOpen(false);
    };

    const handleClickOutsidePopover = (event) => {
        if (popperAnchorEl && !popperAnchorEl.contains(event.target)) {
            handlePopoverClose();
        }
    };

    const handleCancel = () => {
        setOpenModal(false);
        setMenuAnchorEl(null);
        setToggleShowTips(false)
        setPopperAnchorEl(null);
        setSelectedVoice('');
        setSelectedResponse('');
        setSelectedToken('');
        // setInstruction("");
    }

    const handleSave = () => {
        setOpenModal(false);
        setMenuAnchorEl(null);
        setToggleShowTips(false)
        setPopperAnchorEl(null);
        setInstruction(instruction);
    }

    useEffect(() => {
        if (isPopoverOpen) {
            document.addEventListener('click', handleClickOutsidePopover);
        } else {
            document.removeEventListener('click', handleClickOutsidePopover);
        }

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutsidePopover);
        };
    }, [isPopoverOpen]);

    const handleModalClose = () => {
        setOpenModal(false);
        setMenuAnchorEl(null);
        setToggleShowTips(false)
        setPopperAnchorEl(null)
    };
    return (
        <>
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-title">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Custom Instructions
                        </Typography>
                    </div>
                    <div className="custom-inst">
                        <p className='modal-p'>What instructions would you like to provide to get better responses?</p>
                        <div className="modal-text-area">
                            <div className="apvoice">
                                <div className="apvoice-label">
                                    <label htmlFor="">In what voice do you want the response?</label>
                                </div>
                                <div className="custom-select-apvoice">
                                    <select
                                        id="policyDropdown"
                                        value={selectedVoice}
                                        onChange={(e) => {setSelectedVoice(e.target.value)}}
                                        className='sel-cus-apvoice'
                                    >
                                        <option value="Active Voice">Active Voice</option>
                                        <option value="Passive Voice">Passive Voice</option>
                                    </select>
                                </div>
                            </div>
                            <div className="casual-formal">
                                <div className="casual-formal-label">
                                    <label htmlFor="">How do you want the response to be?</label>
                                </div>
                                <div className="custom-select-casual-formal">
                                    <select
                                        id="policyDropdown"
                                        value={selectedResponse}
                                        onChange={(e) => {setSelectedResponse(e.target.value)}}
                                        className='sel-cus-casual-formal'
                                    >
                                        <option value="Casual">Casual</option>
                                        <option value="Formal">Formal</option>
                                    </select>
                                </div>
                            </div>
                            <div className="token-slider">
                                <div className="token-slider-label">
                                    <label htmlFor="">Response should be in how many words?</label>
                                </div>
                                <div className="custom-select-token-slider">
                                    <select
                                        id="policyDropdown"
                                        value={selectedToken}
                                        onChange={(e) => { setSelectedToken(e.target.value) }}
                                        className='sel-cus-token-slider'
                                    >
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                                borderRadius: '0.75rem'
                            }}
                            open={openPopper}
                            anchorReference='anchorPosition'
                            anchorPosition={{ top: 464, left: 979 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            anchorEl={popperAnchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <div className="popover-info">
                                <div className="popover-title">
                                    <Typography className="modal-popover-title" variant="h6" component="h4">
                                        Thought Starters
                                    </Typography>
                                </div>
                                <div className="thought-starters">
                                    <ul>
                                        <li>How formal or causal should the response be?</li>
                                        <li>How long or short the responses should generally be?</li>
                                        <li>In which voice (active/passive) you want the responses?</li>
                                    </ul>
                                </div>
                            </div>
                        </Popover>
                        <div className="modal-confirmation">
                            <button className='btn-neutral' onClick={handleCancel}>Cancel</button>
                            <button className='btn-success' onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default CustomInstructions
