import React, { useRef, useEffect } from "react";
import "./ChatWindow.scss";
import { useSelector } from "react-redux";
import Loader from "react-js-loader";

const ChatWindow = ({ loading }) => {
  const ref = useRef();
  const chatHistory = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chatHistory.length]);

  useEffect(() => {
    const chatDisplay = document.getElementById("chat-display");
    chatDisplay.scrollTop = chatDisplay.scrollHeight;
  }, [chatHistory]);

  return (
    <>
      <div
        className={`chat-window  ${
          chatHistory.chatHistory.length > 0 ? "add-bg" : ""
        }`}
        id="chat-display"
      >
        {chatHistory.chatHistory.length > 0 && (
          <div className={`chat-display`}>
            {chatHistory.chatHistory.map((item, index) => (
              <>
                <div key={index} id="question-answer">
                  <strong>
                    {item.role === "User" ? (
                      <img src={auth.userProfileImage} className="role" />
                    ) : (
                      <span>{item.role}: </span>
                    )}
                  </strong>{" "}
                  <span
                    style={{
                      fontWeight: item.role === "User" ? "bold" : "normal",
                    }}
                  >
                    {item.role === "User" ? (
                      <>{item.text}</>
                    ) : (
                      <pre style={{whiteSpace: 'pre-wrap', display: 'inline'}}>{item.text}</pre>
                    )}
                  </span>
                </div>
                <div ref={ref} />
              </>
            ))}
            {loading === true && (
              <Loader
                type="ping-cube"
                bgColor="rgb(11, 8, 121)"
                // bgColor="rgb(27, 153, 139)"
                color="rgb(11, 8, 121)"
                // color="rgb(27, 153, 139)"
                size={50}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ChatWindow;
