const INITIAL_STATE = {
  isMenuOpen: false
};

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SIDE_MENU_TOGGLE_OPEN":
      return { ...state, isMenuOpen: true };
    case "SIDE_MENU_TOGGLE_CLOSE":
      return { ...state, isMenuOpen: false };
    default:
      return state;
  }
};

export default commonReducer;
