import { combineReducers } from "redux";
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import chatReducer from "./chatReducer";
import widgetRouteReducer from "./widgetRouteReducer";

const reducers = combineReducers({
    auth: authReducer,
    util: commonReducer,
    chat: chatReducer,
    widgetroute: widgetRouteReducer,
});

export default reducers;