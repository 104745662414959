import React, { useState, useRef, useEffect } from "react";
import "./DataAnalysis.scss";
import { Button, CircularProgress, TextField } from "@mui/material";
import { GrAttachment } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import { styled } from "@mui/material/styles";
import CustomInstructions from "../CustomInstructions/CustomInstructions";
import Disclaimer from "../Disclaimer/Disclaimer";
import { _get, _post } from "../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { setChatHistory, resetChatOnRouteChange } from "../../state/actions";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Markdown from "react-markdown";
import { MDXEditor, headingsPlugin, quotePlugin, listsPlugin, thematicBreakPlugin, markdownShortcutPlugin, linkPlugin  } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DataAnalysis = () => {
  const navigate = useNavigate()
  const chatHistory = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [uploadedDoc, setUploadedDoc] = useState();
  const [userInput, setUserInput] = useState("");
  const [summary, setSummary] = useState("");
  const [GraphicWalkerDf, setGraphicWalkerDf] = useState({});
  const [GraphicWalkerColumns, setGraphicWalkerColumns] = useState([]);
  

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [toggleShowTips, setToggleShowTips] = useState(false);

  const [selectedVoice, setSelectedVoice] = useState("Active Voice");
  const [selectedResponse, setSelectedResponse] = useState("Formal");
  const [selectedToken, setSelectedToken] = useState("100");

  const [fields, setFields] = useState([]);

  const [isGraph, setIsGraph] = useState(false);
  const [imageRaster, setImageRaster] = useState("");
  const [isDF, setIsDF] = useState(false);
  const [cols, setCols] = useState([]);
  const [df, setDf] = useState([]);
  const [rowLength, setRowLength] = useState();
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const handlePopoverClose = () => {
    setPopperAnchorEl(null);
    setToggleShowTips(false);
    setIsPopoverOpen(false);
  };

  const handleClickOutsidePopover = (event) => {
    if (popperAnchorEl && !popperAnchorEl.contains(event.target)) {
      handlePopoverClose();
    }
  };


  // Function to handle Graphic Walker data preparation
  const handleGraphicWalker = () => {
    console.log(GraphicWalkerColumns);
    const fields = GraphicWalkerColumns.map((column) => ({
      fid: column,
      semanticType: "nominal", // Default to "nominal"; update as needed
      analyticType: "dimension", // Default to "dimension"; update as needed
    }));

    const dataSource = GraphicWalkerDf[GraphicWalkerColumns[0]].map(
      (_, rowIndex) => {
        const row = {};
        GraphicWalkerColumns.forEach((column) => {
          row[column] = GraphicWalkerDf[column][rowIndex];
        });
        return row;
      }
    );

    const graphicWalkerData = {
      fields,
      dataSource,
    };
    localStorage.clear();
    // localStorage.setItem('graphicWalkerData', JSON.stringify(graphicWalkerData));
    try {
      localStorage.setItem(
        "graphicWalkerData",
        JSON.stringify(graphicWalkerData)
      );
      window.open("/advancedAnalytics", "_blank");
      // navigate("/advancedAnalytics", {state:{df:graphicWalkerData}})
    } catch (e) {
      if (e.code === 22 || e.name === "QuotaExceededError") {
        console.error(
          "LocalStorage quota exceeded. Consider using another storage method."
        );
        toast.warning("File too large to handle by graphic walker!", {
          position: "top-right",
          autoClose: 2500,
          theme: "colored",
          style: { backgroundColor: "rgb(27, 153, 139)" },
        });
        // Implement alternative storage logic here (e.g., IndexedDB)
      } else {
        console.error("Error setting LocalStorage item:", e);
      }
    }
  };

  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 7 * 1000 * 1024) {
          toast.error("File with maximum size of 7MB is allowed", {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          });
        } else {
          setUploadedDoc(file);
        }
      }
      const formData = new FormData();
      formData.append("file", file);
      setFileLoading(true);
      const response = await _post(
        "/upload_file_main?widget_type=ANALYTICS",
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      // const res = await _post(
      //   `/adv_analytics?widget_type=ANALYTICS&filename=${e.target.files[0].name}`
      // );
      setFileLoading(false);

      console.log("API Response:", response.data);
      console.log("API Response type:", typeof response.data);
      // console.log("API Response json:", JSON.parse(atob(response.data))); 
      // console.log("Dataframe: ", response.data.df);
      // console.log("Columns: ", response.data.columns)

      // Create an object where each column name is a key and its values are in an array
      const formattedData = Object.keys(response.data.df).reduce((acc, key) => {
        acc[key] = Object.values(response.data.df[key]);
        return acc;
      }, {});

      console.log("API Response formatted :", formattedData);
      if (response.data.df && response.data.df_columns) {
        setGraphicWalkerDf(formattedData);
        setGraphicWalkerColumns(response.data.df_columns);
      } else {
        console.log("Missing df or columns in the response");
      }

      if (response.data.Response) {
        setSummary(response.data.Response);
        const answer = response.data.Response;
        dispatch(
          setChatHistory({
            role: "Summary",
            text: answer,
          })
        );
      } else {
        console.log("Missing Response in the response data");
      }

      toast.success("File uploaded successfully!", {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
        style: { backgroundColor: "rgb(27, 153, 139)" },
      });
      setFileUploadedSuccessfully(true);
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      console.log(error);
      setFileUploadedSuccessfully(true);
    }
  };

  useEffect(() => {
    console.log(chatHistory);
    console.log(df, typeof df);
    // console.log(chatHistory.chatHistory.text.df)
  }, [chatHistory]);

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("click", handleClickOutsidePopover);
    } else {
      document.removeEventListener("click", handleClickOutsidePopover);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutsidePopover);
    };
  }, [isPopoverOpen]);

  const handleCustomInstructions = () => {
    setOpenModal(true);
  };

  const askQuestion = async () => {
    try {
      dispatch(
        setChatHistory({
          role: "User",
          text: `${userInput} \t(Uploaded Document: ${uploadedDoc.name})`,
        })
      );
      setLoading(true);
      setUserInput("");
      const formData = new FormData();
      formData.append("filename", uploadedDoc.name);
      formData.append("question", uploadedDoc.name);
      const response = await _post(
        `/analysis`,
        {
          filename: uploadedDoc.name,
          question: userInput,
        },
        { "Content-Type": "application/json" }
      );
      console.log(response);
      if (response.data.responseDataType === "graph") {
        setIsGraph(true);
        setIsDF(false);
        setImageRaster(response.data.graph);
        dispatch(
          setChatHistory({
            role: "Analysis Assistant",
            text: { graph: response.data.graph },
          })
        );
      } else if (response.data.responseDataType === "Dataframe") {
        setIsDF(true);
        setIsGraph(false);
        const data = response.data.dataframe;
        const columns = Object.keys(data);
        // const numRows = data[columns[0]].length;
        const numRows = Object.keys(data[columns[0]]).length;

        setRowLength(numRows);
        setCols(columns);
        setDf(data);

        console.log("Dataframe Returned by analysis llm: ", data)
        console.log("Columns : ", columns)
        console.log("numRows = ", numRows)

        dispatch(
          setChatHistory({
            role: "Analysis Assistant",
            // text: data,
            text: { rowLength: numRows, cols: columns, df: data },
          })
        );
      }
      // const answer = response.data.Response;
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      console.error("Error during summarization:", error);
      setLoading(false);
      // Handle the error as needed
    }
  };

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chatHistory.length]);

  useEffect(() => {
    const chatDisplay = document.getElementById("chat-display");
    chatDisplay.scrollTop = chatDisplay.scrollHeight;
  }, [chatHistory]);

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      askQuestion();
    }
  };

  useEffect(() => {
    // dispatch(resetChatOnRouteChange());
  }, []);

  return (
    <>
      <div className={`right-pane-dAna d-flex flex-column`}>
        <div
          className={`chat-window-dAna ${
            chatHistory.chatHistory.length > 0 ? "add-bg-dAna" : ""
          }`}
          id="chat-display"
        >
          {fileUploadedSuccessfully ? (
            <div className="chat-display-dAna">
              {chatHistory.chatHistory.map((item, index) => (
                <>
                  <div
                    key={index}
                    id={`question-answer-dAna`}
                    className={`${item.role === "User" ? "user" : "chat"}`}
                  >
                    <strong>
                      {
                        item.role === "User" && (
                          <>
                            <img
                              src={auth.userProfileImage}
                              className="role"
                              alt={item.role}
                            />
                            <span>{item.text}</span>
                          </>
                        )
                        // : (
                        //   <span>{item.role}: </span>
                        // )
                      }
                    </strong>{" "}
                    <span
                      style={{
                        fontWeight: item.role === "User" ? "bold" : "normal",
                      }}
                    >
                      {/* {item.text} */}
                      {item.role === "Summary" && (
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            {item.role}:{" "}
                          </span>
                          <span>
                          {/* {item.text} */}
                            {/* <Markdown>{item.text}</Markdown> */}
                            <MDXEditor markdown={item.text} plugins={[headingsPlugin(), listsPlugin(), linkPlugin(), quotePlugin(), markdownShortcutPlugin(), thematicBreakPlugin()]} />
                            
                            {/* <ReactMarkdown>{item.text}</ReactMarkdown> */}
                          </span>
                        </>
                      )}
                      {item.role === "Analysis Assistant" && item.text.df && (
                        <>
                          {item.text.cols &&
                            item.text.df &&
                            item.text.rowLength && (
                              <PaginatedTable
                                columns={item.text.cols}
                                data={item.text.df}
                                rowsPerPage={5} // You can set the initial rows per page here
                                rowLength={item.text.rowLength}
                              />
                            )}
                          {/* {item.text.cols &&
                            item.text.df &&
                            item.text.rowLength && (
                              <table>
                                <thead>
                                  <tr>
                                    {item.text.cols.map((column) => {
                                      console.log("testttttt 1");
                                      return <th key={column}>{column}</th>;
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.from(
                                    { length: item.text.rowLength },
                                    (_, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {item.text.cols.map((column) => {
                                          console.log("testttttt 2");
                                          return (
                                            <td key={column}>
                                              {item.text.df[column][rowIndex]}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            )} */}
                        </>
                      )}
                      {item.role === "Analysis Assistant" &&
                        item.text.graph && (
                          <>
                            <img
                              src={`data:image/png;base64,${item.text.graph}`}
                              alt="Chart"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </>
                        )}
                    </span>
                  </div>
                  <div ref={ref} />
                </>
              ))}
              {loading === true && (
                <Loader
                  type="ping-cube"
                  bgColor="rgb(27, 153, 139)"
                  color="rgb(27, 153, 139)"
                  size={50}
                />
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <p>Please choose a file to ask questions on it</p>
            </div>
          )}
        </div>
        <Disclaimer componentName="Data Analysis" policies={false} />
        <div className="container question-box-container">
          <div className="user-query-dAna">
            <div className="navigation-dAna justify-content-between">
              <div className="nav-hr-dAna">
                <div className="custom-upload-dAna">
                  <Button
                    component="label"
                    className="custom-upload-btn-dAna"
                    startIcon={
                        <GrAttachment />
                    }
                    onChange={handleFileUpload}
                  >
                    {fileUploadedSuccessfully
                      ? uploadedDoc.name
                      : "Choose File"}
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </div>
                {fileLoading && (
                  <div className="d-flex flex-row align-items-baseline gap-2">
                    <CircularProgress />
                    <div> Your file is being uploaded</div>
                  </div>
                )}
                {fileUploadedSuccessfully ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#1b998b", color: "#fff" }}
                    onClick={handleGraphicWalker}
                    className="custom-graphicWalker-btn-dAna"
                    // disabled={!fileUploadedSuccessfully}
                  >
                    Do Advanced Analysis
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {fileUploadedSuccessfully ? (
              <div className="user-query-text-btn">
                <TextField
                  label="Type your question..."
                  type="text"
                  variant="standard"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  style={{ width: "100%", marginRight: "20px" }}
                  className="textfield"
                  InputLabelProps={{
                    sx: {
                      ".Mui-focused": {
                        color: "#1b998b",
                      },
                      color: "#1b998b",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "&:focus": {
                        borderBottom: "1px solid #1b998b",
                      },
                      borderBottom: "1px solid #1b998b",
                    },
                  }}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  variant="contained"
                  onClick={askQuestion}
                  className="custom-ask-btn"
                >
                  Ask
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2500} theme="colored" />
    </>
  );
};

export default DataAnalysis;
